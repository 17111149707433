import AbstractClient from "@/api/AbstractClient";
import { TimetableDailySummaryResponse, TimetableSummaryResponse } from "@/api/timetable/types/Responses";

export default class TimetableSummaryClient extends AbstractClient {
  /**
   * Perform get timetable summary request
   * @param schoolClassId school class ID
   */
  static getTimetableSummary(schoolClassId: number): Promise<TimetableSummaryResponse> {
    return AbstractClient.get("/api/timetable-summary/by-school-class/" + schoolClassId)
      .then((response) => new TimetableSummaryResponse(response.data));
  }

  /**
   * Perform get timetable daily summary request
   * @param schoolClassId school class ID
   * @param date date
   */
  static getTimetableDailySummary(schoolClassId: number, date: moment.Moment): Promise<TimetableDailySummaryResponse> {
    return AbstractClient.get("/api/timetable-summary/by-school-class/" + schoolClassId + "/by-date/" + date.format("YYYY-MM-DD"))
      .then((response) => new TimetableDailySummaryResponse(response.data));
  }
}
