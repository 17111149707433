












import ConversationClient from "@/api/chat/ConversationClient";
import { ConversationResponse } from "@/api/chat/types/Responses";
import ConversationsList from "@/components/chat/misc/ConversationsList.vue";
import MessagesList from "@/components/chat/misc/MessagesList.vue";
import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import _ from "lodash";

export default Vue.extend({
  name: "Conversation",
  components: { MessagesList, ConversationsList, DefaultLayout },
  data: () => ({
    // loading
    isBusyConversation: false,
    // data
    conversation: null as ConversationResponse | null,
  }),
  computed: {
    conversationId(): number {
      return _.toInteger(this.$route.params.conversationId);
    },
  },
  watch: {
    conversationId(): void {
      this.fetchConversation();
    },
  },
  methods: {
    fetchConversation(): void {
      this.isBusyConversation = true;
      ConversationClient.getConversation(this.conversationId)
        .then((response) => {
          this.conversation = response;
        })
        .finally(() => {
          this.isBusyConversation = false;
        });
    },
  },
  created(): void {
    this.fetchConversation();
  },
});
