






















































































import Vue, { PropType } from "vue";
import { validationMixin } from "vuelidate";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import { TranslateResult } from "vue-i18n";
import { CreateChildApologiseEventRequest, UpdateChildApologiseEventRequest } from "@/api/events/types/Requests";
import { ChildApologiseEventResponse } from "@/api/events/types/Responses";

export default Vue.extend({
  name: "ChildApologiseEventForm",
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
    allowedDates: {
      type: Array as PropType<moment.Moment[]>,
      required: true,
    },
    defaults: {
      type: Object as PropType<ChildApologiseEventResponse>,
      required: false,
    },
  },
  data: () => ({
    // form data
    form: {
      date: "" as string,
      message: null as string | null,
    },
    // dialogs
    dateDialog: false,
  }),
  validations: {
    form: {
      date: { required },
      message: {},
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit;
    },
    dateFormatted(): string {
      return this.form.date ? moment(this.form.date).format("LL") : "";
    },
    dateErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date?.$dirty) return errors;
      !this.$v.form.date.required && errors.push(this.$t("events.validation.date.required"));
      return errors;
    },
    messageErrors(): TranslateResult[] {
      return [];
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.defaults) {
        const request: UpdateChildApologiseEventRequest = {
          date: this.form.date,
          time: "00:00:00",
          message: this.form.message,
        };
        this.$emit("onSubmit", request);
      } else {
        const request: CreateChildApologiseEventRequest = {
          date: this.form.date,
          time: "00:00:00",
          created_for: this.child.id,
          school_class: this.schoolClass.id,
          message: this.form.message,
        };
        this.$emit("onSubmit", request);
      }
    },
    onClose(): void {
      this.$emit("onClose");
    },
    onDelete(): void {
      if (!confirm(this.$t("events.confirm_delete").toString())) {
        return;
      }
      this.$emit("onDelete");
    },
    initDefaults(): void {
      if (this.defaults) {
        this.form.date = this.defaults.datetime.format("YYYY-MM-DD");
        this.form.message = this.defaults.message;
      }
    },
    checkAllowedDate(date: string): boolean {
      return this.allowedDates
        .map((allowedDate: moment.Moment): string => allowedDate.format("YYYY-MM-DD"))
        .includes(date);
    },
  },
  created(): void {
    this.initDefaults();
  },
});
