




























































































































































































































import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import _ from "lodash";
import Vue, { PropType } from "vue";
import {
  CreateChildIsPickedUpEventTemplateRequest,
  UpdateChildIsPickedUpEventTemplateRequest,
} from "@/api/events/types/Requests";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { TranslateResult } from "vue-i18n";
import { ChildIsPickedUpEventTemplateResponse } from "@/api/events/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import moment from "moment";
import { FamilyResponse } from "@/api/family/types/Responses";
import { UserDetailResponse, UserResponse } from "@/api/user/types/Responses";

export default Vue.extend({
  name: "ChildIsPickedUpEventTemplateForm",
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolYear: {
      type: Object as PropType<SchoolYearResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
    family: {
      type: null as unknown as PropType<FamilyResponse | null>,
      required: true,
    },
    defaults: {
      type: Object as PropType<ChildIsPickedUpEventTemplateResponse>,
      required: false,
    },
  },
  data: () => ({
    // form data
    form: {
      date_from: "" as string,
      date_to: "" as string,
      time: "" as string,
      message: null as string | null,
      authorized_persons: [] as number[],
      weekdays: [0, 1, 2, 3, 4] as number[],
    },
    // dialogs
    dateFromDialog: false,
    dateToDialog: false,
    timeDialog: false,
  }),
  validations: {
    form: {
      date_from: {
        required,
        valid: function(): boolean {
          return this.form.date_from && this.form.date_to
            ? moment(this.form.date_from).isSameOrBefore(moment(this.form.date_to))
            : true;
        },
      },
      date_to: {
        required,
        valid: function(): boolean {
          return this.form.date_from && this.form.date_to
            ? moment(this.form.date_from).isSameOrBefore(moment(this.form.date_to))
            : true;
        },
      },
      time: { required },
      message: {},
      authorized_persons: {},
      weekdays: { required },
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.family === null;
    },
    weekdays(): TranslateResult[] {
      return ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
        .map((weekday: string): TranslateResult => this.$t("events.weekdays." + weekday));
    },
    authorizedPersons(): UserDetailResponse[] {
      return this.family ? this.family.authorized_persons : [];
    },
    dateFromFormatted(): string {
      return this.form.date_from ? moment(this.form.date_from).format("LL") : "";
    },
    dateToFormatted(): string {
      return this.form.date_to ? moment(this.form.date_to).format("LL") : "";
    },
    dateFromErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_from?.$dirty) return errors;
      !this.$v.form.date_from.required && errors.push(this.$t("events.validation.date_from.required"));
      !this.$v.form.date_from.valid && errors.push(this.$t("events.validation.date_from.valid"));
      return errors;
    },
    dateToErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date_to?.$dirty) return errors;
      !this.$v.form.date_to.required && errors.push(this.$t("events.validation.date_to.required"));
      !this.$v.form.date_to.valid && errors.push(this.$t("events.validation.date_to.valid"));
      return errors;
    },
    timeErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.time?.$dirty) return errors;
      !this.$v.form.time.required && errors.push(this.$t("events.validation.time.required"));
      return errors;
    },
    authorizedPersonsErrors(): TranslateResult[] {
      return [];
    },
    messageErrors(): TranslateResult[] {
      return [];
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      if (this.defaults) {
        const request: UpdateChildIsPickedUpEventTemplateRequest = {
          date_from: this.form.date_from,
          date_to: this.form.date_to,
          time: this.form.time + ":00",
          repeat_on_monday: this.form.weekdays.includes(0) ? "true" : "false",
          repeat_on_tuesday: this.form.weekdays.includes(1) ? "true" : "false",
          repeat_on_wednesday: this.form.weekdays.includes(2) ? "true" : "false",
          repeat_on_thursday: this.form.weekdays.includes(3) ? "true" : "false",
          repeat_on_friday: this.form.weekdays.includes(4) ? "true" : "false",
          repeat_on_saturday: this.form.weekdays.includes(5) ? "true" : "false",
          repeat_on_sunday: this.form.weekdays.includes(6) ? "true" : "false",
          message: this.form.message,
          authorized_persons: this.form.authorized_persons,
          regenerate_edited_events: true,
        };
        this.$emit("onSubmit", request);
      } else {
        const request: CreateChildIsPickedUpEventTemplateRequest = {
          date_from: this.form.date_from,
          date_to: this.form.date_to,
          time: this.form.time + ":00",
          created_for: this.child.id,
          school_class: this.schoolClass.id,
          repeat_on_monday: this.form.weekdays.includes(0) ? "true" : "false",
          repeat_on_tuesday: this.form.weekdays.includes(1) ? "true" : "false",
          repeat_on_wednesday: this.form.weekdays.includes(2) ? "true" : "false",
          repeat_on_thursday: this.form.weekdays.includes(3) ? "true" : "false",
          repeat_on_friday: this.form.weekdays.includes(4) ? "true" : "false",
          repeat_on_saturday: this.form.weekdays.includes(5) ? "true" : "false",
          repeat_on_sunday: this.form.weekdays.includes(6) ? "true" : "false",
          message: this.form.message,
          authorized_persons: this.form.authorized_persons,
        };
        this.$emit("onSubmit", request);
      }
    },
    onClose(): void {
      this.$emit("onClose");
    },
    onDelete(): void {
      if (!confirm(this.$t("event_templates.confirm_delete").toString())) {
        return;
      }
      this.$emit("onDelete");
    },
    checkAllowedDate(date: string): boolean {
      return moment(date).isBetween(this.schoolYear.date_from, this.schoolYear.date_to, "days", "[]");
    },
    initDefaults(): void {
      if (this.defaults) {
        this.form.date_from = this.defaults.date_from.format("YYYY-MM-DD");
        this.form.date_to = this.defaults.date_to.format("YYYY-MM-DD");
        this.form.time = this.defaults.time.format("HH:mm");
        this.form.message = this.defaults.message;
        this.form.authorized_persons = this.defaults.authorized_persons.map((user: UserResponse): number => user.id);
        if (this.defaults.repeat_on_monday) this.form.weekdays.push(0);
        if (this.defaults.repeat_on_tuesday) this.form.weekdays.push(1);
        if (this.defaults.repeat_on_wednesday) this.form.weekdays.push(2);
        if (this.defaults.repeat_on_thursday) this.form.weekdays.push(3);
        if (this.defaults.repeat_on_friday) this.form.weekdays.push(4);
        if (this.defaults.repeat_on_saturday) this.form.weekdays.push(5);
        if (this.defaults.repeat_on_sunday) this.form.weekdays.push(6);
      }
    },
    allowedMinutes(minutes: string): boolean {
      return _.toInteger(minutes) % 5 === 0;
    },
  },
  created(): void {
    this.initDefaults();
  },
});
