

















import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import {
  AbstractEventResponse,
  ChildApologiseEventResponse,
  ChildIsPickedUpEventResponse,
  ChildLeavesAloneEventResponse,
} from "@/api/events/types/Responses";
import {
  UpdateChildApologiseEventRequest,
  UpdateChildIsPickedUpEventRequest,
  UpdateChildLeavesAloneEventRequest,
} from "@/api/events/types/Requests";
import EventClient from "@/api/events/EventClient";
import EventForm from "@/components/events/form/EventForm.vue";

export default Vue.extend({
  name: "EditEventForm",
  components: { EventForm },
  props: {
    event: {
      type: Object as PropType<AbstractEventResponse>,
      required: true,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmitChildIsPickedUpEvent: false,
    isBusySubmitChildLeavesAloneEvent: false,
    isBusySubmitChildApologiseEvent: false,
    isBusyDelete: false,
  }),
  methods: {
    onSubmitChildIsPickedUpEvent(request: UpdateChildIsPickedUpEventRequest): void {
      this.isBusySubmitChildIsPickedUpEvent = true;
      EventClient.updateChildIsPickedUpEvent(this.event.id, request)
        .then((response: ChildIsPickedUpEventResponse) => {
          this.$snackbarSuccess(this.$t("events.event_updated"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildIsPickedUpEvent = false;
        });
    },
    onSubmitChildLeavesAloneEvent(request: UpdateChildLeavesAloneEventRequest): void {
      this.isBusySubmitChildLeavesAloneEvent = true;
      EventClient.updateChildLeavesAloneEvent(this.event.id, request)
        .then((response: ChildLeavesAloneEventResponse) => {
          this.$snackbarSuccess(this.$t("events.event_updated"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildLeavesAloneEvent = false;
        });
    },
    onSubmitChildApologiseEvent(request: UpdateChildApologiseEventRequest): void {
      this.isBusySubmitChildApologiseEvent = true;
      EventClient.updateChildApologiseEvent(this.event.id, request)
        .then((response: ChildApologiseEventResponse) => {
          this.$snackbarSuccess(this.$t("events.event_created"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildApologiseEvent = false;
        });
    },
    onDelete(): void {
      this.isBusyDelete = true;
      EventClient.deleteEvent(this.event.id)
        .then(() => {
          this.$snackbarSuccess(this.$t("events.event_deleted"));
          this.$emit("onDelete");
        })
        .finally(() => {
          this.isBusyDelete = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
