






























































import TimetableSummaryClient from "@/api/timetable/TimetableSummaryClient";
import { TimetableSummaryResponse } from "@/api/timetable/types/Responses";
import moment from "moment";
import Vue, { PropType } from "vue";
import { TranslateResult } from "vue-i18n";
import { FamilyResponse } from "@/api/family/types/Responses";
import {
  AbstractEventResponse,
  ChildApologiseEventResponse,
  ChildIsPickedUpEventResponse,
  ChildLeavesAloneEventResponse,
} from "@/api/events/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import FamilyClient from "@/api/family/FamilyClient";
import {
  CreateChildApologiseEventRequest,
  CreateChildIsPickedUpEventRequest,
  CreateChildLeavesAloneEventRequest,
  UpdateChildApologiseEventRequest,
  UpdateChildIsPickedUpEventRequest,
  UpdateChildLeavesAloneEventRequest,
} from "@/api/events/types/Requests";
import ChildIsPickedUpEventForm from "@/components/events/form/ChildIsPickedUpEventForm.vue";
import ChildLeavesAloneEventForm from "@/components/events/form/ChildLeavesAloneEventForm.vue";
import ChildApologiseEventForm from "@/components/events/form/ChildApologiseEventForm.vue";

export default Vue.extend({
  name: "EventForm",
  components: { ChildApologiseEventForm, ChildLeavesAloneEventForm, ChildIsPickedUpEventForm },
  props: {
    isBusySubmitChildIsPickedUpEvent: {
      type: Boolean,
      required: true,
    },
    isBusySubmitChildLeavesAloneEvent: {
      type: Boolean,
      required: true,
    },
    isBusySubmitChildApologiseEvent: {
      type: Boolean,
      required: true,
    },
    isBusyDelete: {
      type: Boolean,
      default: () => false,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
    defaults: {
      type: Object as PropType<AbstractEventResponse>,
      required: false,
    },
  },
  data: () => ({
    // loading
    isBusyFamily: false,
    isBusyTimetableSummary: false,
    // tab
    tab: 0 as number,
    // enum data
    family: null as FamilyResponse | null,
    timetableSummary: null as TimetableSummaryResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyFamily
        || this.isBusyTimetableSummary
        || this.isBusyDelete
        || this.isBusySubmitChildIsPickedUpEvent
        || this.isBusySubmitChildLeavesAloneEvent
        || this.isBusySubmitChildApologiseEvent;
    },
    dialogTitle(): TranslateResult {
      return !this.defaults
        ? this.$t("events.create_event")
        : this.$t("events.edit_event");
    },
    childIsPickedUpAllowedDates(): moment.Moment[] {
      return this.timetableSummary !== null ? this.timetableSummary.create_pick_up_child_event_dates : [];
    },
    childLeavesAloneAllowedDates(): moment.Moment[] {
      return this.timetableSummary !== null ? this.timetableSummary.create_pick_up_child_event_dates : [];
    },
    childApologiseAllowedDates(): moment.Moment[] {
      return this.timetableSummary !== null ? this.timetableSummary.create_pick_up_child_event_dates : [];
    },
  },
  methods: {
    fetchFamily(): void {
      this.isBusyFamily = true;
      FamilyClient.getMyFamily()
        .then((response) => {
          this.family = response;
        })
        .finally(() => {
          this.isBusyFamily = false;
        });
    },
    fetchTimetableSummary(): void {
      this.isBusyTimetableSummary = true;
      TimetableSummaryClient.getTimetableSummary(this.schoolClass.id)
        .then((response) => {
          this.timetableSummary = response;
        })
        .finally(() => {
          this.isBusyTimetableSummary = false;
        });
    },
    onSubmitChildIsPickedUpEvent(request: CreateChildIsPickedUpEventRequest | UpdateChildIsPickedUpEventRequest): void {
      this.$emit("onSubmitChildIsPickedUpEvent", request);
    },
    onSubmitChildLeavesAloneEvent(request: CreateChildLeavesAloneEventRequest | UpdateChildLeavesAloneEventRequest): void {
      this.$emit("onSubmitChildLeavesAloneEvent", request);
    },
    onSubmitChildApologiseEvent(request: CreateChildApologiseEventRequest | UpdateChildApologiseEventRequest): void {
      this.$emit("onSubmitChildApologiseEvent", request);
    },
    onClose(): void {
      this.$emit("onClose");
    },
    onDelete(): void {
      this.$emit("onDelete");
    },
    initTabs(): void {
      if (this.defaults) {
        if (this.defaults instanceof ChildIsPickedUpEventResponse) this.tab = 0;
        if (this.defaults instanceof ChildLeavesAloneEventResponse) this.tab = 1;
        if (this.defaults instanceof ChildApologiseEventResponse) this.tab = 2;
      }
    },
  },
  created(): void {
    this.fetchFamily();
    this.fetchTimetableSummary();
    this.initTabs();
  },
});
