









import UserClient from "@/api/user/UserClient";
import PageTitle from "@/components/layout/PageTitle.vue";
import SubscriptionPlans from "@/components/subscription/SubscriptionPlans.vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Vue from "vue";

export default Vue.extend({
  name: "Subscriptions",
  components: { SubscriptionPlans, PageTitle, DefaultLayout },
  data: () => ({
    isBusyMyProfile: false,
  }),
  watch: {
    success(success: boolean): void {
      if (success) {
        this.onSuccess();
      }
    },
    failure(failure: boolean): void {
      if (failure) {
        this.onFailure();
      }
    },
    cancel(cancel: boolean): void {
      if (cancel) {
        this.onCancel();
      }
    },
  },
  computed: {
    success(): boolean {
      return this.$route.query.success === "true";
    },
    failure(): boolean {
      return this.$route.query.failure === "true";
    },
    cancel(): boolean {
      return this.$route.query.cancel === "true";
    },
  },
  methods: {
    onSuccess(): void {
      this.$snackbarSuccess(this.$t("subscriptions.subscription_changed_successfully"));
      this.isBusyMyProfile = true;
      setTimeout(() => {
        this.fetchMyProfile();
      }, 4000);
    },
    onFailure(): void {
      this.$snackbarError(this.$t("subscriptions.subscription_change_failure"));
      this.isBusyMyProfile = true;
      setTimeout(() => {
        this.fetchMyProfile();
      }, 4000);
    },
    onCancel(): void {
      this.$snackbarSuccess(this.$t("subscriptions.subscription_canceled_successfully"));
      this.isBusyMyProfile = true;
      setTimeout(() => {
        this.fetchMyProfile();
      }, 4000);
    },
    fetchMyProfile(): void {
      this.isBusyMyProfile = true;
      UserClient.getMyProfile()
        .then((response) => {
          if (response.founder) {
            this.$store.commit("Stripe/setStripePriceId", response.founder.stripe_price_id);
            this.$store.commit("Stripe/setStripeSubscriptionId", response.founder.stripe_subscription_id);
          } else {
            this.$store.commit("Stripe/setStripePriceId", null);
            this.$store.commit("Stripe/setStripeSubscriptionId", null);
          }
          window.location.replace(this.$router.resolve({ name: "subscriptions" }).href);
        })
        .finally(() => {
          this.isBusyMyProfile = false;
        });
    },
  },
  created(): void {
    if (this.success) {
      this.onSuccess();
    } else if (this.failure) {
      this.onFailure();
    } else if (this.cancel) {
      this.onCancel();
    }
  },
});
