var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.dateFormatted,"error-messages":_vm.dateErrors,"disabled":_vm.isBusy,"label":_vm.$t('events.date'),"prepend-inner-icon":"mdi-calendar","readonly":"","filled":"","rounded":"","single-line":""},on:{"input":function($event){return _vm.$v.form.date.$touch()},"blur":function($event){return _vm.$v.form.date.$touch()}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateDialog),callback:function ($$v) {_vm.dateDialog=$$v},expression:"dateDialog"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1","allowed-dates":_vm.checkAllowedDate,"no-title":""},on:{"input":function($event){_vm.dateDialog = false}},model:{value:(_vm.$v.form.date.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.date, "$model", $$v)},expression:"$v.form.date.$model"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-menu',{ref:"time-menu",attrs:{"close-on-content-click":false,"return-value":_vm.$v.form.time.$model,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.$v.form.time, "$model", $event)},"update:return-value":function($event){return _vm.$set(_vm.$v.form.time, "$model", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('events.time'),"prepend-inner-icon":"mdi-clock-time-four-outline","error-messages":_vm.timeErrors,"disabled":_vm.isBusy || !_vm.form.date || _vm.isBusyTimetableDailySummary,"readonly":"","filled":"","rounded":"","single-line":""},on:{"input":function($event){return _vm.$v.form.time.$touch()},"blur":function($event){return _vm.$v.form.time.$touch()}},scopedSlots:_vm._u([(_vm.isBusyTimetableDailySummary)?{key:"append",fn:function(){return [_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"20"}})]},proxy:true}:null],null,true),model:{value:(_vm.$v.form.time.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.time, "$model", $$v)},expression:"$v.form.time.$model"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeDialog),callback:function ($$v) {_vm.timeDialog=$$v},expression:"timeDialog"}},[_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","allowed-hours":_vm.checkAllowedHour,"allowed-minutes":_vm.checkAllowedMinute},on:{"click:minute":function($event){return _vm.$refs['time-menu'].save(_vm.$v.form.time.$model)},"click:hour":_vm.onClickHour},model:{value:(_vm.$v.form.time.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.time, "$model", $$v)},expression:"$v.form.time.$model"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.authorizedPersons,"item-value":"id","item-text":"full_name","label":_vm.$t('events.authorized_persons'),"hint":_vm.$t('events.authorized_persons_hint'),"error-messages":_vm.authorizedPersonsErrors,"disabled":_vm.isBusy,"persistent-hint":"","multiple":"","filled":"","rounded":"","single-line":""},on:{"input":function($event){return _vm.$v.form.authorized_persons.$touch()},"blur":function($event){return _vm.$v.form.authorized_persons.$touch()}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.full_name)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.authorized_person.authorized_person_type.name)+" ")])],1)]}}],null,true)},'v-list-item',attrs,false),on))]}}]),model:{value:(_vm.$v.form.authorized_persons.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.authorized_persons, "$model", $$v)},expression:"$v.form.authorized_persons.$model"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('events.message'),"hint":_vm.$t('events.message_hint'),"error-messages":_vm.messageErrors,"disabled":_vm.isBusy,"rows":"3","filled":"","rounded":"","single-line":"","persistent-hint":""},on:{"input":function($event){return _vm.$v.form.message.$touch()},"blur":function($event){return _vm.$v.form.message.$touch()}},model:{value:(_vm.$v.form.message.$model),callback:function ($$v) {_vm.$set(_vm.$v.form.message, "$model", $$v)},expression:"$v.form.message.$model"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.isBusy,"color":"primary","type":"submit","text":""}},[_vm._v(" "+_vm._s(_vm.$t("button.save"))+" ")]),_c('v-btn',{attrs:{"disabled":_vm.isBusy,"color":"secondary","text":"","type":"button"},on:{"click":_vm.onClose}},[_vm._v(" "+_vm._s(_vm.$t("button.close"))+" ")]),(_vm.defaults)?[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.isBusy,"color":"error","text":"","type":"button"},on:{"click":_vm.onDelete}},[_vm._v(" "+_vm._s(_vm.$t("button.delete"))+" ")])]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }