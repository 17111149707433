
















import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import Vue, { PropType } from "vue";
import {
  AbstractEventTemplateResponse,
  ChildIsPickedUpEventTemplateResponse,
  ChildLeavesAloneEventTemplateResponse,
} from "@/api/events/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import EventTemplateForm from "@/components/events/form/EventTemplateForm.vue";
import EventTemplateClient from "@/api/events/EventTemplateClient";
import {
  UpdateChildIsPickedUpEventTemplateRequest,
  UpdateChildLeavesAloneEventTemplateRequest,
} from "@/api/events/types/Requests";

export default Vue.extend({
  name: "EditEventTemplateForm",
  components: { EventTemplateForm },
  props: {
    eventTemplate: {
      type: Object as PropType<AbstractEventTemplateResponse>,
      required: true,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolYear: {
      type: Object as PropType<SchoolYearResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmitChildIsPickedUpEventTemplate: false,
    isBusySubmitChildLeavesAloneEventTemplate: false,
    isBusyDelete: false,
  }),
  methods: {
    onSubmitChildIsPickedUpEventTemplate(request: UpdateChildIsPickedUpEventTemplateRequest): void {
      this.isBusySubmitChildIsPickedUpEventTemplate = true;
      EventTemplateClient.updateChildIsPickedUpEventTemplate(this.eventTemplate.id, request)
        .then((response: ChildIsPickedUpEventTemplateResponse) => {
          this.$snackbarSuccess(this.$t("event_templates.event_template_updated"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildIsPickedUpEventTemplate = false;
        });
    },
    onSubmitChildLeavesAloneEventTemplate(request: UpdateChildLeavesAloneEventTemplateRequest): void {
      this.isBusySubmitChildLeavesAloneEventTemplate = true;
      EventTemplateClient.updateChildLeavesAloneEventTemplate(this.eventTemplate.id, request)
        .then((response: ChildLeavesAloneEventTemplateResponse) => {
          this.$snackbarSuccess(this.$t("event_templates.event_template_updated"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildLeavesAloneEventTemplate = false;
        });
    },
    onDelete(): void {
      this.isBusyDelete = true;
      EventTemplateClient.deleteEventTemplate(this.eventTemplate.id)
        .then(() => {
          this.$snackbarSuccess(this.$t("event_templates.event_template_deleted"));
          this.$emit("onDelete");
        })
        .finally(() => {
          this.isBusyDelete = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
