






































































import Vue, { PropType } from "vue";
import { AbstractEventResponse } from "@/api/events/types/Responses";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import EditEventForm from "@/components/events/form/EditEventForm.vue";

export default Vue.extend({
  name: "EventCard",
  components: { EditEventForm },
  props: {
    event: {
      type: Object as PropType<AbstractEventResponse>,
      required: true,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // dialogs
    editEventDialog: false,
  }),
});
