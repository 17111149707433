















































import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import Vue, { PropType } from "vue";
import {
  AbstractEventTemplateResponse,
  ChildIsPickedUpEventTemplateResponse,
  ChildLeavesAloneEventTemplateResponse,
} from "@/api/events/types/Responses";
import ChildIsPickedUpEventTemplateForm from "@/components/events/form/ChildIsPickedUpEventTemplateForm.vue";
import ChildLeavesAloneEventTemplateForm from "@/components/events/form/ChildLeavesAloneEventTemplateForm.vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { FamilyResponse } from "@/api/family/types/Responses";
import FamilyClient from "@/api/family/FamilyClient";
import {
  CreateChildIsPickedUpEventTemplateRequest,
  CreateChildLeavesAloneEventTemplateRequest,
  UpdateChildIsPickedUpEventTemplateRequest,
  UpdateChildLeavesAloneEventTemplateRequest,
} from "@/api/events/types/Requests";
import { TranslateResult } from "vue-i18n";

export default Vue.extend({
  name: "EventTemplateForm",
  components: { ChildLeavesAloneEventTemplateForm, ChildIsPickedUpEventTemplateForm },
  props: {
    isBusySubmitChildIsPickedUpEventTemplate: {
      type: Boolean,
      required: true,
    },
    isBusySubmitChildLeavesAloneEventTemplate: {
      type: Boolean,
      required: true,
    },
    isBusyDelete: {
      type: Boolean,
      default: () => false,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolYear: {
      type: Object as PropType<SchoolYearResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
    defaults: {
      type: Object as PropType<AbstractEventTemplateResponse>,
      required: false,
    },
  },
  data: () => ({
    // loading
    isBusyFamily: false,
    // tab
    tab: 0 as number,
    // family
    family: null as FamilyResponse | null,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyFamily
        || this.isBusyDelete
        || this.isBusySubmitChildIsPickedUpEventTemplate
        || this.isBusySubmitChildLeavesAloneEventTemplate;
    },
    dialogTitle(): TranslateResult {
      return !this.defaults
        ? this.$t("event_templates.create_event_template")
        : this.$t("event_templates.edit_event_template");
    },
  },
  methods: {
    fetchFamily(): void {
      this.isBusyFamily = true;
      FamilyClient.getMyFamily()
        .then((response) => {
          this.family = response;
        })
        .finally(() => {
          this.isBusyFamily = false;
        });
    },
    onSubmitChildIsPickedUpEventTemplate(request: CreateChildIsPickedUpEventTemplateRequest | UpdateChildIsPickedUpEventTemplateRequest): void {
      this.$emit("onSubmitChildIsPickedUpEventTemplate", request);
    },
    onSubmitChildLeavesAloneEventTemplate(request: CreateChildLeavesAloneEventTemplateRequest | UpdateChildLeavesAloneEventTemplateRequest): void {
      this.$emit("onSubmitChildLeavesAloneEventTemplate", request);
    },
    onClose(): void {
      this.$emit("onClose");
    },
    onDelete(): void {
      this.$emit("onDelete");
    },
    initTabs(): void {
      if (this.defaults) {
        if (this.defaults instanceof ChildIsPickedUpEventTemplateResponse) this.tab = 0;
        if (this.defaults instanceof ChildLeavesAloneEventTemplateResponse) this.tab = 1;
      }
    },
  },
  created(): void {
    this.fetchFamily();
    this.initTabs();
  },
});
