





































































import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { AbstractEventResponse } from "@/api/events/types/Responses";
import EventClient from "@/api/events/EventClient";
import { AllItems } from "@/api/AbstractClient";
import EventDetail from "@/components/events/calendar/EventDetail.vue";
import moment from "moment";

export default Vue.extend({
  name: "EventsCalendar",
  components: { EventDetail },
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyEvents: false,
    // mounted flag
    ready: false,
    // calendar model
    focus: "",
    // calendar type (month, week, day)
    type: "month",
    // calendar data
    events: [] as AbstractEventResponse[],
    // open event dialog
    selectedEvent: null as AbstractEventResponse | null,
    selectedElement: null,
    selectedOpen: false,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyEvents;
    },
    weekdays(): number[] {
      return [1, 2, 3, 4, 5, 6, 0];
    },
    types(): any [] {
      return ["month", "week", "day"];
    },
    formattedEvents(): any[] {
      return this.events.map((event: AbstractEventResponse) => ({
        name: event.name,
        start: event.datetime.toDate(),
        end: event.datetime.add(1, "hour").toDate(),
        color: event.color,
        timed: event.is_timed,
        event: event,
      }));
    },
  },
  methods: {
    onPrevious(): void {
      (this.$refs["calendar"] as any).prev();
    },
    onToday(): void {
      this.focus = "";
    },
    onNext(): void {
      (this.$refs["calendar"] as any).next();
    },
    onClickEvent(event: any): void {
      const open = () => {
        this.selectedEvent = event.event.event;
        this.selectedElement = event.nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
      };
      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }
      event.nativeEvent.stopPropagation();
    },
    onClickDay(event: any): void {
      this.focus = event.date;
      this.type = "day";
    },
    fetchEvents(event: any): void {
      const filter = [
        "gte:date:\"" + moment(event.start.date).set("hours", 0).set("minutes", 0).set("seconds", 0).format() + "\"",
        "lte:date:\"" + moment(event.end.date).set("hours", 0).set("minutes", 0).set("seconds", 0).format() + "\"",
      ].join(";");
      this.isBusyEvents = true;
      EventClient.getEvents(this.schoolClass.id, this.child.id, new AllItems(filter))
        .then((response) => {
          this.events = response.items;
        })
        .finally(() => {
          this.isBusyEvents = false;
        });
    },
    getEventColor(event: AbstractEventResponse): string {
      return event.color;
    },
  },
  mounted(): void {
    this.ready = true;
  },
});
