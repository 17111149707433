




























































































































































import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import HelpMessage from "@/components/layout/HelpMessage.vue";
import Vue from "vue";
import DefaultLayout from "@/layouts/DefaultLayout.vue";
import EventsCalendar from "@/components/events/calendar/EventsCalendar.vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import PageTitle from "@/components/layout/PageTitle.vue";
import SmallEventsCalendar from "@/components/events/calendar/SmallEventsCalendar.vue";
import EventTemplatesList from "@/components/events/list/EventTemplatesList.vue";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";
import CreateEventTemplateForm from "@/components/events/form/CreateEventTemplateForm.vue";
import EventsList from "@/components/events/list/EventsList.vue";
import CreateEventForm from "@/components/events/form/CreateEventForm.vue";

export default Vue.extend({
  name: "Events",
  components: {
    HelpMessage,
    CreateEventForm,
    EventsList,
    CreateEventTemplateForm,
    PageSubtitle,
    EventTemplatesList,
    SmallEventsCalendar,
    PageTitle,
    EventsCalendar,
    DefaultLayout,
  },
  data: () => ({
    // dialogs
    calendarDialog: false,
    createEventTemplateDialog: false,
    createEventDialog: false,
    // show occurred events switch
    showOccurredEvents: false,
    showOccurredEventTemplates: false,
  }),
  computed: {
    selectedChild(): ChildResponse | null {
      return this.$store.getters["Family/getSelectedChild"];
    },
    selectedSchoolClass(): SchoolClassResponse | null {
      const child = this.selectedChild;
      if (child === null) return null;
      const childToSchoolClass = this.$store.getters["Family/getChildToSchoolClass"](child);
      return childToSchoolClass !== null ? childToSchoolClass.schoolClass : null;
    },
    schoolYear(): SchoolYearResponse | null {
      const child = this.selectedChild;
      if (child === null) return null;
      const childToSchoolClass = this.$store.getters["Family/getChildToSchoolClass"](child);
      return childToSchoolClass !== null ? childToSchoolClass.schoolClass.schoolYear : null;
    },
  },
});
