














import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import EventForm from "@/components/events/form/EventForm.vue";
import {
  CreateChildApologiseEventRequest,
  CreateChildIsPickedUpEventRequest,
  CreateChildLeavesAloneEventRequest,
} from "@/api/events/types/Requests";
import {
  ChildApologiseEventResponse,
  ChildIsPickedUpEventResponse,
  ChildLeavesAloneEventResponse,
} from "@/api/events/types/Responses";
import EventClient from "@/api/events/EventClient";

export default Vue.extend({
  name: "CreateEventForm",
  components: { EventForm },
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmitChildIsPickedUpEvent: false,
    isBusySubmitChildLeavesAloneEvent: false,
    isBusySubmitChildApologiseEvent: false,
  }),
  methods: {
    onSubmitChildIsPickedUpEvent(request: CreateChildIsPickedUpEventRequest): void {
      this.isBusySubmitChildIsPickedUpEvent = true;
      EventClient.createChildIsPickedUpEvent(request)
        .then((response: ChildIsPickedUpEventResponse) => {
          this.$snackbarSuccess(this.$t("events.event_created"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildIsPickedUpEvent = false;
        });
    },
    onSubmitChildLeavesAloneEvent(request: CreateChildLeavesAloneEventRequest): void {
      this.isBusySubmitChildLeavesAloneEvent = true;
      EventClient.createChildLeavesAloneEvent(request)
        .then((response: ChildLeavesAloneEventResponse) => {
          this.$snackbarSuccess(this.$t("events.event_created"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildLeavesAloneEvent = false;
        });
    },
    onSubmitChildApologiseEvent(request: CreateChildApologiseEventRequest): void {
      this.isBusySubmitChildApologiseEvent = true;
      EventClient.createChildApologiseEvent(request)
        .then((response: ChildApologiseEventResponse) => {
          this.$snackbarSuccess(this.$t("events.event_created"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildApologiseEvent = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
