















































































import { FilterQueryParameters } from "@/api/AbstractClient";
import ConversationClient from "@/api/chat/ConversationClient";
import MessageClient from "@/api/chat/MessageClient";
import { CreateMessageRequest } from "@/api/chat/types/Requests";
import { ConversationParticipationResponse, ConversationResponse, MessageResponse } from "@/api/chat/types/Responses";
import moment from "moment";
import Vue, { PropType } from "vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default Vue.extend({
  name: "MessagesList",
  mixins: [validationMixin],
  props: {
    conversation: {
      type: Object as PropType<ConversationResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyMessages: false,
    isBusyNewMessages: false,
    isBusyMarkConversationAsRead: false,
    isBusySubmit: false,
    // data
    messages: [] as MessageResponse[],
    // form data
    form: {
      content: "",
    },
    // pagination
    page: 1,
    perPage: 50,
    // all messages fetched
    allFetched: false,
  }),
  validations: {
    form: {
      content: { required },
    },
  },
  watch: {
    conversation(): void {
      this.fetchMessages(true);
      this.markConversationAsRead();
    },
    isBusy(value: boolean): void {
      if (!value) {
        this.$nextTick(() => {
          (this.$refs["message"] as any).focus();
        });
      }
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyMessages || this.isBusySubmit;
    },
    otherParticipantsFullNames(): string[] {
      return this.conversation.participations
        .filter((participation: ConversationParticipationResponse): boolean => participation.participant.id !== this.$store.getters["User/getId"])
        .map((participation: ConversationParticipationResponse): string => participation.participant.full_name);
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      const request: CreateMessageRequest = {
        content: this.form.content,
      };

      this.isBusySubmit = true;
      MessageClient.createMessage(this.conversation.id, request)
        .then((response) => {
          this.messages.unshift(response);
          this.form.content = "";
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
    onLoadOlderMessages(): void {
      if (this.allFetched) {
        return;
      }
      this.page += 1;
      this.fetchMessages();
    },
    isMyMessage(message: MessageResponse): boolean {
      return message.author_id === this.$store.getters["User/getId"];
    },
    authorFullName(this: any, message: MessageResponse): string {
      const participation = this.conversation.participations
        .find((participation: ConversationParticipationResponse): boolean => participation.participant.id === message.author_id);
      return participation ? participation.participant.full_name : "";
    },
    markConversationAsRead(): void {
      this.isBusyMarkConversationAsRead = true;
      ConversationClient.markConversationAsRead(this.conversation.id)
        .then(() => {
          // do nothing
        })
        .finally(() => {
          this.isBusyMarkConversationAsRead = false;
        });
    },
    fetchMessages(reset = false): void {
      if (reset) {
        this.messages = [];
        this.page = 1;
        this.allFetched = false;
      }

      const pagination: FilterQueryParameters = {
        page: this.page,
        itemsPerPage: this.perPage,
        filter: "",
        sort: "created_at:desc",
      };

      this.isBusyMessages = true;
      MessageClient.getMessages(this.conversation.id, pagination)
        .then((response) => {
          this.storeMessages(response.items, true);
          this.allFetched = response._pagination.total <= response._pagination.page * response._pagination.items_per_page;
        })
        .finally(() => {
          this.isBusyMessages = false;
        });
    },
    fetchNewMessages(datetime: moment.Moment): void {
      this.isBusyNewMessages = true;
      MessageClient.getNewMessages(this.conversation.id, datetime)
        .then((response) => {
          this.storeMessages(response);
        })
        .finally(() => {
          this.isBusyNewMessages = false;
        });
    },
    storeMessages(newMessages: MessageResponse[], atTheEnd = false): void {
      newMessages.forEach((newMessage: MessageResponse): void => {
        if (!this.messages.find((message) => message.id === newMessage.id)) {
          if (atTheEnd) {
            this.messages.push(newMessage);
          } else {
            this.messages.unshift(newMessage);
          }
        }
      });
    },
    initPolling(): void {
      setInterval(() => {
        if (this.messages.length === 0) {
          return;
        }
        this.fetchNewMessages(this.messages[0].created_at);
      }, 2000);
    },
  },
  created(): void {
    this.fetchMessages();
    this.markConversationAsRead();

    this.initPolling();
  },
});
