import _ from "lodash";
import moment from "moment";

export abstract class AbstractTimetableRecordResponse {
  readonly id: number;
  readonly type: string;
  readonly color: string;
  readonly name: string;
  readonly message: string | null;
  readonly school_year_id: number | null;
  readonly school_class_id: number | null;
  readonly date_from: moment.Moment;
  readonly date_to: moment.Moment;

  protected constructor(data: any) {
    this.id = data.id;
    this.type = data.type;
    this.color = data.color;
    this.name = data.name;
    this.message = data.message;
    this.school_year_id = data.school_year_id;
    this.school_class_id = data.school_class_id;
    this.date_from = moment(data.date_from);
    this.date_to = moment(data.date_to);
  }
}

export class PossibilityToBringChildTimetableRecordResponse extends AbstractTimetableRecordResponse {
  readonly time_from: moment.Moment;
  readonly time_to: moment.Moment;
  readonly repeat_on_monday: boolean;
  readonly repeat_on_tuesday: boolean;
  readonly repeat_on_wednesday: boolean;
  readonly repeat_on_thursday: boolean;
  readonly repeat_on_friday: boolean;
  readonly repeat_on_saturday: boolean;
  readonly repeat_on_sunday: boolean;

  constructor(data: any) {
    super(data);
    this.time_from = moment(data.time_from, ["h:m a", "H:m"]);
    this.time_to = moment(data.time_to, ["h:m a", "H:m"]);
    this.repeat_on_monday = data.repeat_on_monday;
    this.repeat_on_tuesday = data.repeat_on_tuesday;
    this.repeat_on_wednesday = data.repeat_on_wednesday;
    this.repeat_on_thursday = data.repeat_on_thursday;
    this.repeat_on_friday = data.repeat_on_friday;
    this.repeat_on_saturday = data.repeat_on_saturday;
    this.repeat_on_sunday = data.repeat_on_sunday;
  }
}

export class PossibilityToPickUpChildTimetableRecordResponse extends AbstractTimetableRecordResponse {
  readonly time_from: moment.Moment;
  readonly time_to: moment.Moment;
  readonly repeat_on_monday: boolean;
  readonly repeat_on_tuesday: boolean;
  readonly repeat_on_wednesday: boolean;
  readonly repeat_on_thursday: boolean;
  readonly repeat_on_friday: boolean;
  readonly repeat_on_saturday: boolean;
  readonly repeat_on_sunday: boolean;

  constructor(data: any) {
    super(data);
    this.time_from = moment(data.time_from, ["h:m a", "H:m"]);
    this.time_to = moment(data.time_to, ["h:m a", "H:m"]);
    this.repeat_on_monday = data.repeat_on_monday;
    this.repeat_on_tuesday = data.repeat_on_tuesday;
    this.repeat_on_wednesday = data.repeat_on_wednesday;
    this.repeat_on_thursday = data.repeat_on_thursday;
    this.repeat_on_friday = data.repeat_on_friday;
    this.repeat_on_saturday = data.repeat_on_saturday;
    this.repeat_on_sunday = data.repeat_on_sunday;
  }
}

export class HolidayTimetableRecordResponse extends AbstractTimetableRecordResponse {
  constructor(data: any) {
    super(data);
  }
}

export class TimetableSummaryResponse {
  readonly create_bring_child_event_dates: moment.Moment[];
  readonly create_pick_up_child_event_dates: moment.Moment[];

  constructor(data: any) {
    this.create_bring_child_event_dates = _.toArray(data.create_bring_child_event_dates).map((date: string): moment.Moment => moment(date));
    this.create_pick_up_child_event_dates = _.toArray(data.create_pick_up_child_event_dates).map((date: string): moment.Moment => moment(date));
  }
}

export class TimetableDailySummaryResponse {
  readonly create_bring_child_event_time_intervals: TimetableTimeIntervalResponse[];
  readonly create_pick_up_child_event_time_intervals: TimetableTimeIntervalResponse[];

  constructor(data: any) {
    this.create_bring_child_event_time_intervals = _.toArray(data.create_bring_child_event_time_intervals).map((interval: any) => new TimetableTimeIntervalResponse(interval));
    this.create_pick_up_child_event_time_intervals = _.toArray(data.create_pick_up_child_event_time_intervals).map((interval: any) => new TimetableTimeIntervalResponse(interval));
  }
}

export class TimetableTimeIntervalResponse {
  readonly time_from: moment.Moment;
  readonly time_to: moment.Moment;

  constructor(data: any) {
    this.time_from = moment(data.time_from, ["h:m a", "H:m"]);
    this.time_to = moment(data.time_to, ["h:m a", "H:m"]);
  }
}
