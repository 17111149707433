








































































































import DefaultLayout from "@/layouts/DefaultLayout.vue";
import Vue from "vue";
import { FamilyResponse } from "@/api/family/types/Responses";
import AuthorizedPersonsList from "@/components/family/misc/AuthorizedPersonsList.vue";
import ChildrenList from "@/components/family/misc/ChildrenList.vue";
import FamilyClient from "@/api/family/FamilyClient";
import PageTitle from "@/components/layout/PageTitle.vue";
import PageSubtitle from "@/components/layout/PageSubtitle.vue";
import CreateAuthorizedPersonForm from "@/components/users/form/CreateAuthorizedPersonForm.vue";
import MyApplicationsTable from "@/components/applications/table/MyApplicationsTable.vue";
import NewApplicationForm from "@/components/applications/form/NewApplicationForm.vue";

export default Vue.extend({
  name: "FamilyMembers",
  components: {
    NewApplicationForm,
    MyApplicationsTable,
    CreateAuthorizedPersonForm,
    PageSubtitle,
    PageTitle,
    ChildrenList,
    AuthorizedPersonsList,
    DefaultLayout,
  },
  data: () => ({
    // loading
    isBusyFamily: false,
    // data
    family: null as FamilyResponse | null,
    // dialog
    createAuthorizedPersonDialog: false,
    createApplicationDialog: false,
  }),
  computed: {
    isBusy(): boolean {
      return this.isBusyFamily;
    },
  },
  methods: {
    onNewApplicationSubmit(): void {
      this.createApplicationDialog = false;
      (this.$refs["my-applications-table"] as any).refresh();
    },
    fetchFamily(loading = true): void {
      if (loading) {
        this.isBusyFamily = true;
      }
      FamilyClient.getMyFamily()
        .then((response) => {
          this.family = response;
        })
        .finally(() => {
          this.isBusyFamily = false;
        });
    },
  },
  created(): void {
    this.fetchFamily();
  },
});
