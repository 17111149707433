













import AbstractClient from "@/api/AbstractClient";
import EventClient from "@/api/events/EventClient";
import { AbstractEventTemplateResponse } from "@/api/events/types/Responses";
import DataTable, { DataTableHeader } from "@/components/data-table/DataTable.vue";
import Vue, { PropType } from "vue";
import _ from "lodash";

export default Vue.extend({
  name: "SubordinateEventsTable",
  components: { DataTable },
  props: {
    eventTemplate: {
      type: Object as PropType<AbstractEventTemplateResponse>,
      required: true,
    },
  },
  computed: {
    client(): AbstractClient {
      return new EventClient();
    },
    clientFunction(): any {
      return EventClient.getEventsByEventTemplate;
    },
    clientFunctionParameters(): any[] {
      return [this.eventTemplate.id];
    },
    headers(): DataTableHeader[] {
      return [
        {
          text: _.toString(this.$t("events.datetime")),
          value: "datetime",
          sortable: false,
        },
      ];
    },
  },
});
