




import Vue from "vue";
import AuthorizedPersonForm from "@/components/users/form/AuthorizedPersonForm.vue";
import UserClient from "@/api/user/UserClient";
import { UserDetailResponse } from "@/api/user/types/Responses";
import { CreateAuthorizedPersonRequest } from "@/api/user/types/Requests";

export default Vue.extend({
  name: "CreateAuthorizedPersonForm",
  components: { AuthorizedPersonForm },
  data: () => ({
    // loading
    isBusySubmit: false,
  }),
  methods: {
    onSubmit(request: CreateAuthorizedPersonRequest): void {
      this.isBusySubmit = true;
      UserClient.createAuthorizedPerson(request)
        .then((response: UserDetailResponse) => {
          this.$snackbarSuccess(this.$t("family.authorized_person_created"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmit = false;
        });
    },
  },
});
