var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{ref:"table",attrs:{"client":_vm.client,"client-function":_vm.clientFunction,"headers":_vm.headers,"actions":_vm.actions},scopedSlots:_vm._u([{key:"item.school",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.school.name)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at.format("LLL"))+" ")]}},{key:"item.processed_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.processed_at !== null ? item.processed_at.format("LLL") : "-")+" ")]}},{key:"item.processed_by",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.processed_by !== null ? item.processed_by.full_name : "-")+" ")]}},{key:"item.is_processed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_processed ? _vm.$t("applications.processed") : _vm.$t("applications.unprocessed"))+" ")]}}])}),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.applicationDetailDialog),callback:function ($$v) {_vm.applicationDetailDialog=$$v},expression:"applicationDetailDialog"}},[(_vm.applicationToDetail !== null)?_c('application-detail',{attrs:{"application":_vm.applicationToDetail},on:{"onClose":function($event){_vm.applicationDetailDialog = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }