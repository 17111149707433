













import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import Vue, { PropType } from "vue";
import EventTemplateForm from "@/components/events/form/EventTemplateForm.vue";
import EventTemplateClient from "@/api/events/EventTemplateClient";
import {
  ChildIsPickedUpEventTemplateResponse,
  ChildLeavesAloneEventTemplateResponse,
} from "@/api/events/types/Responses";
import {
  CreateChildIsPickedUpEventTemplateRequest,
  CreateChildLeavesAloneEventTemplateRequest,
} from "@/api/events/types/Requests";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";

export default Vue.extend({
  name: "CreateEventTemplateForm",
  components: { EventTemplateForm },
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolYear: {
      type: Object as PropType<SchoolYearResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusySubmitChildIsPickedUpEventTemplate: false,
    isBusySubmitChildLeavesAloneEventTemplate: false,
  }),
  methods: {
    onSubmitChildIsPickedUpEventTemplate(request: CreateChildIsPickedUpEventTemplateRequest): void {
      this.isBusySubmitChildIsPickedUpEventTemplate = true;
      EventTemplateClient.createChildIsPickedUpEventTemplate(request)
        .then((response: ChildIsPickedUpEventTemplateResponse) => {
          this.$snackbarSuccess(this.$t("event_templates.event_template_created"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildIsPickedUpEventTemplate = false;
        });
    },
    onSubmitChildLeavesAloneEventTemplate(request: CreateChildLeavesAloneEventTemplateRequest): void {
      this.isBusySubmitChildLeavesAloneEventTemplate = true;
      EventTemplateClient.createChildLeavesAloneEventTemplate(request)
        .then((response: ChildLeavesAloneEventTemplateResponse) => {
          this.$snackbarSuccess(this.$t("event_templates.event_template_created"));
          this.$emit("onSubmit", response);
        })
        .finally(() => {
          this.isBusySubmitChildLeavesAloneEventTemplate = false;
        });
    },
    onClose(): void {
      this.$emit("onClose");
    },
  },
});
