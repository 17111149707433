





























import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { AllItems } from "@/api/AbstractClient";
import { AbstractEventTemplateResponse } from "@/api/events/types/Responses";
import EventTemplateClient from "@/api/events/EventTemplateClient";
import EventTemplateCard from "@/components/events/card/EventTemplateCard.vue";
import HelpMessage from "@/components/layout/HelpMessage.vue";
import moment from "moment";

export default Vue.extend({
  name: "EventTemplatesList",
  components: { HelpMessage, EventTemplateCard },
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolYear: {
      type: Object as PropType<SchoolYearResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
    showOccurredEventTemplates: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyEventTemplates: false,
    // data
    eventTemplates: [] as AbstractEventTemplateResponse[],
  }),
  watch: {
    showOccurredEventTemplates(): void {
      this.fetchEventTemplates(true);
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyEventTemplates;
    },
  },
  methods: {
    fetchEventTemplates(reset = false): void {
      if (reset) {
        this.eventTemplates = [];
      }
      const filters = [];
      if (!this.showOccurredEventTemplates) {
        const date = moment().set("hours", 0).set("minutes", 0).set("seconds", 0);
        filters.push("gte:date_to:\"" + date.format() + "\"");
      }
      this.isBusyEventTemplates = true;
      EventTemplateClient.getEventTemplates(this.schoolClass.id, this.child.id, new AllItems(filters.join(";")))
        .then((response) => {
          this.eventTemplates = response.items;
        })
        .finally(() => {
          this.isBusyEventTemplates = false;
        });
    },
  },
  created(): void {
    this.fetchEventTemplates();
  },
});
