




























import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { AllItems } from "@/api/AbstractClient";
import { AbstractEventResponse } from "@/api/events/types/Responses";
import EventClient from "@/api/events/EventClient";
import HelpMessage from "@/components/layout/HelpMessage.vue";
import EventCard from "@/components/events/card/EventCard.vue";
import EventType from "@/enums/EventType";
import moment from "moment";

export default Vue.extend({
  name: "EventsList",
  components: { EventCard, HelpMessage },
  props: {
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
    showOccurredEvents: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    // loading
    isBusyEvents: false,
    // data
    events: [] as AbstractEventResponse[],
  }),
  watch: {
    showOccurredEvents(): void {
      this.fetchEvents(true);
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusyEvents;
    },
  },
  methods: {
    fetchEvents(reset = false): void {
      if (reset) {
        this.events = [];
      }
      const filters = [
        "eq:is_templated_event:\"false\"",
        "in:type:(" + [EventType.CHILD_IS_PICKED_UP, EventType.CHILD_LEAVES_ALONE, EventType.CHILD_APOLOGISE].map((type: string) => "\"" + type + "\"").join(",") + ")",
      ];
      if (!this.showOccurredEvents) {
        const date = moment().set("hours", 0).set("minutes", 0).set("seconds", 0);
        filters.push("gte:date:\"" + date.format() + "\"");
      }
      this.isBusyEvents = true;
      EventClient.getEvents(this.schoolClass.id, this.child.id, new AllItems(filters.join(";")))
        .then((response) => {
          this.events = response.items;
        })
        .finally(() => {
          this.isBusyEvents = false;
        });
    },
  },
  created(): void {
    this.fetchEvents();
  },
});
