import AbstractClient, { FilterQueryParameters } from "@/api/AbstractClient";
import { CreateMessageRequest } from "@/api/chat/types/Requests";
import { MessageResponse } from "@/api/chat/types/Responses";
import { PaginatedResponse } from "@/api/common/types/Responses";
import moment from "moment";

export default class MessageClient extends AbstractClient {
  /**
   * Perform get messages by conversation request
   * @param conversationId conversation ID
   * @param pagination pagination/filters/sort configuration
   */
  static getMessages(conversationId: number, pagination: FilterQueryParameters): Promise<PaginatedResponse<MessageResponse>> {
    return AbstractClient.get("/api/chat/conversations/" + conversationId + "/messages", pagination)
      .then((response) => PaginatedResponse.fromResponseData(MessageResponse, response.data));
  }

  /**
   * Perform get new messages request
   * @param conversationId conversation ID
   * @param datetime last received message created at datetime
   */
  static getNewMessages(conversationId: number, datetime: moment.Moment): Promise<MessageResponse[]> {
    return AbstractClient.get("/api/chat/conversations/" + conversationId + "/messages/new-since/" + datetime.format())
      .then((response) => response.data.map((item: any) => new MessageResponse(item)));
  }

  /**
   * Perform create message request
   * @param conversationId conversation ID
   * @param request create message request
   */
  static createMessage(conversationId: number, request: CreateMessageRequest): Promise<MessageResponse> {
    return AbstractClient.post("/api/chat/conversations/" + conversationId + "/messages", request)
      .then((response) => new MessageResponse(response.data));
  }
}
