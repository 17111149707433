









































































































































































import TimetableSummaryClient from "@/api/timetable/TimetableSummaryClient";
import { TimetableDailySummaryResponse } from "@/api/timetable/types/Responses";
import _ from "lodash";
import Vue, { PropType } from "vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";
import { FamilyResponse } from "@/api/family/types/Responses";
import { ChildIsPickedUpEventResponse } from "@/api/events/types/Responses";
import { validationMixin } from "vuelidate";
import { UserDetailResponse, UserResponse } from "@/api/user/types/Responses";
import { TranslateResult } from "vue-i18n";
import { CreateChildIsPickedUpEventRequest, UpdateChildIsPickedUpEventRequest } from "@/api/events/types/Requests";
import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default Vue.extend({
  name: "ChildIsPickedUpEventForm",
  mixins: [validationMixin],
  props: {
    isBusySubmit: {
      type: Boolean,
      required: true,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
    family: {
      type: null as unknown as PropType<FamilyResponse | null>,
      required: true,
    },
    allowedDates: {
      type: Array as PropType<moment.Moment[]>,
      required: true,
    },
    defaults: {
      type: Object as PropType<ChildIsPickedUpEventResponse>,
      required: false,
    },
  },
  data: () => ({
    // loading
    isBusyTimetableDailySummary: false,
    // form data
    form: {
      date: "" as string,
      time: "" as string,
      message: null as string | null,
      authorized_persons: [] as number[],
    },
    // dialogs
    dateDialog: false,
    timeDialog: false,
    // timetable daily summary
    timetableDailySummary: null as TimetableDailySummaryResponse | null,
    // selected hour via time picker
    hour: "0" as string,
    // form initialized
    initialized: false,
  }),
  validations: {
    form: {
      date: { required },
      time: { required },
      message: {},
      authorized_persons: {},
    },
  },
  watch: {
    "form.date"(date: string): void {
      if (this.initialized) {
        this.form.time = "";
        if (!_.isEmpty(date)) {
          this.fetchTimetableDailySummary(moment(date));
        }
      }
    },
  },
  computed: {
    isBusy(): boolean {
      return this.isBusySubmit || this.family === null;
    },
    authorizedPersons(): UserDetailResponse[] {
      return this.family ? this.family.authorized_persons : [];
    },
    dateFormatted(): string {
      return this.form.date ? moment(this.form.date).format("LL") : "";
    },
    dateErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.date?.$dirty) return errors;
      !this.$v.form.date.required && errors.push(this.$t("events.validation.date.required"));
      return errors;
    },
    timeErrors(): TranslateResult[] {
      const errors: TranslateResult[] = [];
      if (!this.$v.form.time?.$dirty) return errors;
      !this.$v.form.time.required && errors.push(this.$t("events.validation.time.required"));
      return errors;
    },
    authorizedPersonsErrors(): TranslateResult[] {
      return [];
    },
    messageErrors(): TranslateResult[] {
      return [];
    },
  },
  methods: {
    onSubmit(): void {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      if (this.defaults) {
        const request: UpdateChildIsPickedUpEventRequest = {
          date: this.form.date,
          time: this.form.time + ":00",
          message: this.form.message,
          authorized_persons: this.form.authorized_persons,
        };
        this.$emit("onSubmit", request);
      } else {
        const request: CreateChildIsPickedUpEventRequest = {
          date: this.form.date,
          time: this.form.time + ":00",
          created_for: this.child.id,
          school_class: this.schoolClass.id,
          message: this.form.message,
          authorized_persons: this.form.authorized_persons,
        };
        this.$emit("onSubmit", request);
      }
    },
    onClose(): void {
      this.$emit("onClose");
    },
    onDelete(): void {
      if (!confirm(this.$t("events.confirm_delete").toString())) {
        return;
      }
      this.$emit("onDelete");
    },
    onClickHour(hour: string) {
      this.hour = hour;
    },
    initDefaults(): void {
      if (this.defaults) {
        this.form.date = this.defaults.datetime.format("YYYY-MM-DD");
        this.form.time = this.defaults.datetime.format("HH:mm");
        this.form.message = this.defaults.message;
        this.form.authorized_persons = this.defaults.authorized_persons.map((user: UserResponse): number => user.id);
        this.hour = this.defaults.datetime.format("H");
      }
      this.$nextTick(() => this.initialized = true);
    },
    checkAllowedDate(date: string): boolean {
      return this.allowedDates
        .map((allowedDate: moment.Moment): string => allowedDate.format("YYYY-MM-DD"))
        .includes(date);
    },
    checkAllowedHour(hour: string): boolean {
      if (this.timetableDailySummary === null) return false;
      for (let i = 0; i < this.timetableDailySummary.create_pick_up_child_event_time_intervals.length; i++) {
        const interval = this.timetableDailySummary.create_pick_up_child_event_time_intervals[i];
        if (interval.time_from.format("H") <= hour && interval.time_to.format("H") >= hour) {
          return true;
        }
      }
      return false;
    },
    checkAllowedMinute(minute: string): boolean {
      const time = moment().set("hours", _.toInteger(this.hour)).set("minutes", _.toInteger(minute)).format("HH:mm");
      if (this.timetableDailySummary === null) return false;
      for (let i = 0; i < this.timetableDailySummary.create_pick_up_child_event_time_intervals.length; i++) {
        const interval = this.timetableDailySummary.create_pick_up_child_event_time_intervals[i];
        if (interval.time_from.format("HH:mm") <= time
          && interval.time_to.format("HH:mm") >= time
          && _.toInteger(minute) % 5 === 0) {
          return true;
        }
      }
      return false;
    },
    fetchTimetableDailySummary(date: moment.Moment): void {
      this.isBusyTimetableDailySummary = true;
      TimetableSummaryClient.getTimetableDailySummary(this.schoolClass.id, date)
        .then((response) => {
          this.timetableDailySummary = response;
        })
        .finally(() => {
          this.isBusyTimetableDailySummary = false;
        });
    },
  },
  created(): void {
    this.initDefaults();
  },
});
