import AbstractClient, { FilterQueryParameters } from "@/api/AbstractClient";
import { PaginatedResponse } from "@/api/common/types/Responses";
import {
  AbstractEventTemplateResponse,
  ChildIsPickedUpEventTemplateResponse,
  ChildLeavesAloneEventTemplateResponse,
} from "@/api/events/types/Responses";
import EventTemplateType from "@/enums/EventTemplateType";
import {
  CreateChildIsPickedUpEventTemplateRequest,
  CreateChildLeavesAloneEventTemplateRequest,
  UpdateChildIsPickedUpEventTemplateRequest,
  UpdateChildLeavesAloneEventTemplateRequest,
} from "@/api/events/types/Requests";
import { AxiosResponse } from "axios";
import _ from "lodash";

export default class EventTemplateClient extends AbstractClient {
  /**
   * Perform get event templates request
   * @param schoolClassId school class ID
   * @param childId child ID
   * @param pagination pagination/filters/sort configuration
   */
  static getEventTemplates(schoolClassId: number, childId: number, pagination: FilterQueryParameters): Promise<PaginatedResponse<AbstractEventTemplateResponse>> {
    return AbstractClient.get("/api/event-templates/by-class/" + schoolClassId + "/by-child/" + childId, pagination)
      .then((response) => {
        const eventTemplates = _.toArray(response.data.items).map((data: any): AbstractEventTemplateResponse => EventTemplateClient.buildEventTemplate(data));
        return PaginatedResponse.fromResponseDataItems(eventTemplates, response.data._pagination);
      });
  }

  /**
   * Perform get event template request
   * @param eventTemplateId event template ID
   */
  static getEventTemplate(eventTemplateId: number): Promise<AbstractEventTemplateResponse> {
    return AbstractClient.get("/api/event-templates/" + eventTemplateId)
      .then((response) => EventTemplateClient.buildEventTemplate(response.data));
  }

  /**
   * Perform create "child is picked up" event template request
   * @param request create "child is picked up" event template request
   */
  static createChildIsPickedUpEventTemplate(request: CreateChildIsPickedUpEventTemplateRequest): Promise<ChildIsPickedUpEventTemplateResponse> {
    return AbstractClient.post("/api/event-templates/child-is-picked-up", request)
      .then((response) => new ChildIsPickedUpEventTemplateResponse(response.data));
  }

  /**
   * Perform create "child leaves alone" event template request
   * @param request create "child leaves alone" event template request
   */
  static createChildLeavesAloneEventTemplate(request: CreateChildLeavesAloneEventTemplateRequest): Promise<ChildLeavesAloneEventTemplateResponse> {
    return AbstractClient.post("/api/event-templates/child-leaves-alone", request)
      .then((response) => new ChildLeavesAloneEventTemplateResponse(response.data));
  }

  /**
   * Perform update "child is picked up" event template request
   * @param eventTemplateId event template ID
   * @param request update "child is picked up" event template request
   */
  static updateChildIsPickedUpEventTemplate(eventTemplateId: number, request: UpdateChildIsPickedUpEventTemplateRequest): Promise<ChildIsPickedUpEventTemplateResponse> {
    return AbstractClient.patch("/api/event-templates/child-is-picked-up/" + eventTemplateId, request)
      .then((response) => new ChildIsPickedUpEventTemplateResponse(response.data));
  }

  /**
   * Perform update "child leaves alone" event template request
   * @param eventTemplateId event template ID
   * @param request update "child leaves alone" event template request
   */
  static updateChildLeavesAloneEventTemplate(eventTemplateId: number, request: UpdateChildLeavesAloneEventTemplateRequest): Promise<ChildLeavesAloneEventTemplateResponse> {
    return AbstractClient.patch("/api/event-templates/child-leaves-alone/" + eventTemplateId, request)
      .then((response) => new ChildLeavesAloneEventTemplateResponse(response.data));
  }

  /**
   * Perform delete event template
   * @param eventTemplateId event template ID
   */
  static deleteEventTemplate(eventTemplateId: number): Promise<AxiosResponse> {
    return AbstractClient.delete("/api/event-templates/" + eventTemplateId);
  }

  /**
   * Build event template of specific type from response data
   * @param data response data
   * @private
   */
  static buildEventTemplate(data: any): AbstractEventTemplateResponse {
    switch (data.type) {
      case EventTemplateType.CHILD_IS_PICKED_UP:
        return new ChildIsPickedUpEventTemplateResponse(data);
      case EventTemplateType.CHILD_LEAVES_ALONE:
        return new ChildLeavesAloneEventTemplateResponse(data);
      default:
        throw "Unsupported event template type";
    }
  }
}
