

































































































































import EventTemplateClient from "@/api/events/EventTemplateClient";
import { SchoolYearResponse } from "@/api/school-year/types/Responses";
import SubordinateEventsTable from "@/components/events/table/SubordinateEventsTable.vue";
import _ from "lodash";
import Vue, { PropType } from "vue";
import { AbstractEventTemplateResponse } from "@/api/events/types/Responses";
import { TranslateResult } from "vue-i18n";
import EditEventTemplateForm from "@/components/events/form/EditEventTemplateForm.vue";
import { ChildResponse } from "@/api/child/types/Responses";
import { SchoolClassResponse } from "@/api/school-class/types/Responses";

export default Vue.extend({
  name: "EventTemplateCard",
  components: { SubordinateEventsTable, EditEventTemplateForm },
  props: {
    eventTemplate: {
      type: Object as PropType<AbstractEventTemplateResponse>,
      required: true,
    },
    child: {
      type: Object as PropType<ChildResponse>,
      required: true,
    },
    schoolYear: {
      type: Object as PropType<SchoolYearResponse>,
      required: true,
    },
    schoolClass: {
      type: Object as PropType<SchoolClassResponse>,
      required: true,
    },
  },
  data: () => ({
    // local copy of event template passed as property
    localEventTemplate: {} as AbstractEventTemplateResponse,
    // weekdays
    selectedWeekdays: [] as number[],
    // dialogs
    editEventTemplateDialog: false,
    showSubordinateEventsDialog: false,
  }),
  computed: {
    weekdays(): TranslateResult[] {
      return ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]
        .map((weekday: string): TranslateResult => this.$t("events.weekdays." + weekday));
    },
  },
  methods: {
    fetchEventTemplate(): void {
      if (!this.localEventTemplate.is_processing) {
        return;
      }
      EventTemplateClient.getEventTemplate(this.localEventTemplate.id)
        .then((response) => {
          this.localEventTemplate = response;
          setTimeout(() => {
            this.fetchEventTemplate();
          }, 2500);
        });
    },
  },
  created(): void {
    this.localEventTemplate = _.cloneDeep(this.eventTemplate);
    if (this.localEventTemplate.repeat_on_monday) this.selectedWeekdays.push(0);
    if (this.localEventTemplate.repeat_on_tuesday) this.selectedWeekdays.push(1);
    if (this.localEventTemplate.repeat_on_wednesday) this.selectedWeekdays.push(2);
    if (this.localEventTemplate.repeat_on_thursday) this.selectedWeekdays.push(3);
    if (this.localEventTemplate.repeat_on_friday) this.selectedWeekdays.push(4);
    if (this.localEventTemplate.repeat_on_saturday) this.selectedWeekdays.push(5);
    if (this.localEventTemplate.repeat_on_sunday) this.selectedWeekdays.push(6);
    this.fetchEventTemplate();
  },
});
